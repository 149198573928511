<template>
    <div :id="blok._uid"
        class="flex flex-col gap-sm lg:grid lg:grid-cols-2">
        <div v-for="(image) in blok.images"
            :key="image._uid"
            class="relative">
            <v-storyblok-component :is="image.component"
                :blok="image"
                :products="products" />
        </div>
    </div>
</template>

<script setup lang="ts">
import { useQuery } from '@urql/vue';
import { ViewMarkerProductsDocument } from '~/graphql';
import type { ProductImageLifts } from '~~/types';

const properties = defineProps<{
    blok: ProductImageLifts
}>();

const variables = {
    ids: properties.blok.images
        .flatMap((image) => image.product_links)
        .map((marker) => marker?.product?.source?.id)
        .filter((id): id is string => !!id),
};

const { data } = useQuery({
    query: ViewMarkerProductsDocument,
    variables,
    pause: variables.ids.length === 0,
});

const products = computed(() => data.value?.products.edges.map((edge) => edge.node) || []);
</script>
